import { createAsyncThunk } from "@reduxjs/toolkit";
import AUTHAPI from "./api";
import { setCookie } from "cookies-next";

const doLogin = createAsyncThunk<any, any, any>(
  "auth/login",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.login(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetProfile = createAsyncThunk<any, any, any>(
  "auth/getProfile",
  async ({ rejectWithValue }) => {
    try {
      const response = await AUTHAPI.getProfile({});

      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doRegister = createAsyncThunk<any, any, any>(
  "auth/register",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.register(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doVerifyCode = createAsyncThunk<any, any, any>(
  "auth/verifyCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.verifyCode(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doForgetPassword = createAsyncThunk<any, any, any>(
  "auth/forgetPassword",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.forgetPassword(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doChangePassword = createAsyncThunk<any, any, any>(
  "auth/changePassword",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.changePassword(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doResendCode = createAsyncThunk<any, any, any>(
  "auth/resendCode",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await AUTHAPI.resendCode(data);
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doRegisterTeachers = createAsyncThunk<any, any, any>(
  "auth/registerTeachers",
  async (
    { education_type_id, school_year_id, language_id, school_type_id },
    { rejectWithValue }
  ) => {
    try {
      const response = await AUTHAPI.regiterTeachers(
        education_type_id,
        school_year_id,
        language_id,
        school_type_id
      );
      return { data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const thunks = {
  doLogin,
  doGetProfile,
  doRegister,
  doVerifyCode,
  doForgetPassword,
  doChangePassword,
  doResendCode,
  doRegisterTeachers,
};

export default thunks;
