import { getCookie } from "cookies-next";
import axios from "axios";

// https://backend.eduvalu.com => old
// https://server.eduvalu.com => new

export const mainURL = "https://backend.eduvalu.com";
export const baseURL = "https://backend.eduvalu.com/api/v1/user";
export const ADMINURL = "https://backend.eduvalu.com/api/v1/admin";

export const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = getCookie("Education_User_token");
  //
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export const adminApi = axios.create({
  baseURL: ADMINURL,
  headers: {
    Accept: "application/json",
  },
});

adminApi.interceptors.request.use(async (config) => {
  const token = getCookie("Education_token");
  //
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export default api;
