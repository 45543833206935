type TinitialValues = {
  registerTeachers: {
    id: number;
    name: string;
    code: string;
    image: string;
    subject_name: string;
  }[];
  userLogged: {
    isLoggedIn: boolean;
    userToken: string | null;
    userData: {};
  };
};

export const initialValues: TinitialValues = {
  registerTeachers: [
    {
      id: 0,
      name: "",
      code: "",
      image: "",
      subject_name: "",
    },
  ],
  userLogged: {
    isLoggedIn: false,
    userToken: "",
    userData: {},
  },
};
