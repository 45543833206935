import { createSlice } from "@reduxjs/toolkit";
import { initialValues } from "./types";
import thunks from "./thunks";
import { toast } from "react-toastify";
import { setCookie, deleteCookie } from "cookies-next";

export const slice = createSlice({
  name: "auth",
  initialState: initialValues,
  reducers: {
    logout: (state) => {
      state.userLogged.isLoggedIn = false;
      state.userLogged.userToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.doLogin.fulfilled, (state, action) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");

      state.userLogged.isLoggedIn = true;
      state.userLogged.userToken = action.payload.data.data.token;
      setCookie("Education_User_token", action.payload.data.data.token);
      setCookie("seveezToken", action.payload.data.data.token);
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doLogin.rejected, (state, action: any) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");

      state.userLogged.isLoggedIn = false;
      state.userLogged.userToken = null;
      toast.error(
        action?.payload?.response?.data?.error
          ? action?.payload?.response?.data?.error
          : action.payload.response.data.message
      );
    });
    builder.addCase(thunks.doGetProfile.fulfilled, (state, action) => {
      state.userLogged.userToken = action.payload.data.message.token;
      state.userLogged.isLoggedIn = true;
      state.userLogged.userData = action.payload.data.message;
    });
    builder.addCase(thunks.doGetProfile.rejected, (state, action: any) => {
      toast.error(action?.payload?.response?.data?.error);
    });

    builder.addCase(thunks.doRegister.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doRegister.rejected, (state, action: any) => {
      toast.error(action?.payload?.response?.data?.error);
      toast.error(action.payload.data?.message);
    });
    builder.addCase(thunks.doVerifyCode.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doVerifyCode.rejected, (state, action: any) => {
      toast.error(action.payload.response.data.message);
      toast.error(
        action?.payload?.response?.data?.error
          ? action?.payload?.response?.data?.error
          : action.payload.response.data.message
      );
    });
    builder.addCase(thunks.doForgetPassword.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doForgetPassword.rejected, (state, action: any) => {
      toast.error(action.payload.response.data.message);
    });
    builder.addCase(thunks.doChangePassword.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doChangePassword.rejected, (state, action: any) => {
      toast.error(action.payload.response.data.message);
    });
    builder.addCase(thunks.doResendCode.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });
    builder.addCase(thunks.doResendCode.rejected, (state, action: any) => {
      toast.error(action.payload.response.data.message);
    });
    builder.addCase(thunks.doRegisterTeachers.fulfilled, (state, action) => {
      state.registerTeachers = action.payload.data.data;
    });
    builder.addCase(
      thunks.doRegisterTeachers.rejected,
      (state, action: any) => {}
    );
  },
});

export const { logout } = slice.actions;

export const SelectLoggedUser = (state: any) => state.auth.userLogged;

export const SelectRegisterTeachers = (state: any) =>
  state.auth.registerTeachers;

const AUTH = {
  slice,
  thunks,
};

export default AUTH;
