import { combineReducers } from "@reduxjs/toolkit";
import AUTH from "./Auth";
import GENERAL from "./GENERAL";
import ADMIN from "./Admin";
import USER from "./User";

const combineReducer = combineReducers({
  [AUTH.slice.name]: AUTH.slice.reducer,
  [USER.slice.name]: USER.slice.reducer,
  [GENERAL.slice.name]: GENERAL.slice.reducer,
  [ADMIN.slice.name]: ADMIN.slice.reducer,
});

export default combineReducer;
