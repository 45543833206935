type TinitialValues = {
    allCities: [],
    allAreas: [],
    languages: [],
    educationTypes: [],
    schoolYears: [],
    schoolTypes: [],
};

export const initialValues: TinitialValues = {
    allCities: [],
    allAreas: [],
    languages: [],
    educationTypes: [],
    schoolYears: [],
    schoolTypes: [],
};
